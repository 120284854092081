import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const images = [
  require("../../images/machinary/img1.jpg"),
  require("../../images/machinary/img2.jpg"),
  require("../../images/machinary/img3.jpg"),
  require("../../images/machinary/img4.jpg"),
  require("../../images/machinary/img5.jpg"),
  require("../../images/machinary/img6.jpg"),
  require("../../images/machinary/img10.jpg"),
  require("../../images/machinary/img8.jpg"),
  require("../../images/machinary/img9.jpg")
];

class NewFeatures extends Component {

    constructor(props) {
      super(props);

      this.state = {
        photoIndex: 0,
        isOpen: false
      };
    }

    render() {
        const { photoIndex, isOpen } = this.state;
        return (
            <section  id="newfeatures" className="edge ptb-100">
                <div className="container">
                    <div className="tab new-features-update-tab">
                        <div className="row">
                            {
                                images.map((image, index) => {
                                    return (
                                        <div key={index} className="col-lg-4 col-md-4">
                                          <img src={image} alt="new" onClick={() => this.setState({ isOpen: true })} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {isOpen && (
                          <Lightbox
                            imageTitle="Servicios del taller Carrasco Hermanos"
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                            onMovePrevRequest={() =>
                              this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                              })
                            }
                            onMoveNextRequest={() =>
                              this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                              })
                            }
                          />
                        )}
                    </div>
                </div>
            </section>
        );
    }
}

export default NewFeatures;
