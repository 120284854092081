const local = {
  ga_analytics: '',
  url: {
    api: "https://api.labs.carrascohermanos.cl"
  }
};

const dev = {
  ga_analytics: '',
  url: {
    api: "https://api.labs.carrascohermanos.cl"
  }
};

const prod = {
  ga_analytics: 'UA-145853026-5',
  url: {
    api: "https://api.carrascohermanos.cl"
  }
};

const config = process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.trim() === 'production'
  ? prod
  : process.env.REACT_APP_ENV === 'development'
  ? dev
  : local;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
