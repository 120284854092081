import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel3';

const options = {
    loop: true,
    nav: false,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='fas fa-angle-left'></i>",
        "<i class='fas fa-angle-right'></i>"
    ],
    responsive: {
        0: {
            items:1,
        },
        768: {
            items:2,
        },
        1200: {
            items:3,
        }
    }
}

class Services extends Component {
    state = { display:false};

    componentDidMount(){
        this.setState({ display: true })
    }

    renderService(text, img, i) {
      return (
        <div key={i} className="col-lg-12 col-md-12">
            <div className="single-services">
                <div className="icon">
                    <i className="fas fa-tools"></i>
                </div>
                <h3>{text}</h3>
                <div className="service-item">
                    <img src={img} alt="Servicio" />
                </div>
            </div>
        </div>
      );
    }

    render() {
        const services = [
          {'name': 'Revisión de Frenos', 'image': require("../../images/service/frenos.png")},
          {'name': 'Análisis de Gases', 'image': require("../../images/service/gases.png")},
          {'name': 'Revisión pre-compra', 'image': require("../../images/service/chequeo.png")},
          {'name': 'Embrague', 'image': require("../../images/service/embrague.png")},
          {'name': 'Cambio de aceite', 'image': require("../../images/service/aceite.png")},
          {'name': 'Bombas e inyectores', 'image': require("../../images/service/inyectores.png")},
          {'name': 'Balanceo', 'image': require("../../images/service/balanceo.png")},
          {'name': 'Motor', 'image': require("../../images/service/motor.png")},
          {'name': 'Scaner y diagnóstico', 'image': require("../../images/service/scanner.png")}
        ]
        return (
            <section id="services" className="services-area ptb-100">
                <div className="container">
                    <div className="saas-section-title">
                        <h2>Servicios de nuestro taller</h2>
                        <div className="bar"></div>
                        <p>Entregamos un servicio personalizado a nuestros clientes orientado a la mejora continua.</p>
                    </div>

                    <div className="row">
                        {this.state.display ? <OwlCarousel
                            className="services-slides owl-carousel owl-theme"
                            {...options}
                        >

                            {
                              services.map((service, index) => {
                                return this.renderService(service.name, service.image, index);
                              })
                            }

                        </OwlCarousel> : ''}
                    </div>
                </div>
{/*
                <div className="services-inner-area">
                    <div className="container-fluid">
                        <div className="row h-100 justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="services-image">
                                    <img src={require("../../images/laptop.png")} alt="service" />
                                </div>
                            </div>
                            {/*
                            <div className="col-lg-6 col-md-12">
                                <div className="services-inner-content">
                                    <div className="services-item">
                                        <div className="icon">
                                            <i className="fas fa-phone"></i>
                                        </div>
                                        <h3>Free Caliing Service</h3>
                                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                                    </div>

                                    <div className="services-item">
                                        <div className="icon">
                                            <i className="fas fa-gift"></i>
                                        </div>
                                        <h3>Daily Free Gift</h3>
                                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                                    </div>

                                    <div className="services-item">
                                        <div className="icon">
                                            <i className="fas fa-qrcode"></i>
                                        </div>
                                        <h3>QR Code Scaner</h3>
                                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                */}
            </section>
        );
    }
}

export default Services;
