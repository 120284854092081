import React, { Component } from 'react';
import NavbarThreeMachinery from '../components/Layouts/NavbarThreeMachinery';
import Footer from '../components/Layouts/Footer';
import Banner from '../components/Machinery/Banner';
import About from '../components/Machinery/About';
import Contact from '../components/Home/Contact';
import Partner from '../components/Machinery/Partner';

import NewFeatures from '../components/Machinery/NewFeatures';

class Machinery extends Component {
    render() {
        return (
            <React.Fragment>
              <NavbarThreeMachinery />
              <Banner />
              <About />
              <NewFeatures />
              <Partner />
              <Contact />
              <Footer />
            </React.Fragment>
        );
    }
}

export default Machinery;
