import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

class About extends Component {
    render() {
        return (
            <section id="about" className="about-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-lg-12 col-md-12">
                            <div className="about-content">
                                <h2>Banco de pruebas para sistemas de inyección electrónica diesel</h2>
                                <p>Nuestro laboratorio de última generación y experiencia nos permite hacer diagnósticos certeros sobre motores diesel.</p>
                                <p>La reparación de los inyectores incluye todos los procedimientos especificados por cada fabricante, con sus diferentes procesos y comprobaciones en bancos de pruebas especiales de los que disponemos en nuestro taller.</p>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-bong"></i>
                                                </div>

                                                <h3>Scanner y diagnóstico de vehículos electrónico diesel</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-tools"></i>
                                                </div>

                                                <h3>Reparación de bombas inyectoras diesel</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-crutch"></i>
                                                </div>

                                                <h3>Inyectores diesel</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-toolbox"></i>
                                                </div>

                                                <h3>Repuestos para sistema de inyección diesel</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-thumbs-up"></i>
                                                </div>

                                                <h3>Puesta a punto motores diesel</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-question-circle"></i>
                                                </div>

                                                <h3>¿Un problema sin solución? Te ayudamos!</h3>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="section-title">
                                    <AnchorLink offset={() => 0} className="btn btn-primary" href="#newfeatures">Ver más...</AnchorLink>
                                    <AnchorLink offset={() => 0} className="btn btn-primary" href="#contact">Contáctanos</AnchorLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="circle"><img src={require('../../images/circle.png')} alt="cricle" /></div>
            </section>
        );
    }
}

export default About;
