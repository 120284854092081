import React, { Component } from 'react';
import config from '../../config';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            alertMessage: '',
            isErrorMessage: false,
            name: '',
            email: '',
            phone: '',
            message: ''
        };
    }

    handleSubmit = async event => {
      event.preventDefault();
      this.setState({ isLoading: true });
      try {
    	  const response = await this.contactSupport({
          ...this.props.fields,
    	    name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          message: this.state.message
        });
        console.log(response);
        this.setState({ isLoading: false, alertMessage: 'Hemos recibido tu mensaje, pronto nos comunicaremos contigo.', isErrorMessage: false, name: '', email: '', phone: '', message: '' });
      } catch (e) {
        console.error(e);
        this.setState({ isLoading: false, alertMessage: 'Error al enviar mensaje. Favor intenta nuevamente', isErrorMessage: true });
      }
    }

    handleChange = event => {
      this.setState({
        [event.target.id]: event.target.value
      });
    }

    async contactSupport(contact) {
      const result = await fetch(config.url.api + '/contact', {
        method: 'POST',
        headers: { 'content-type': 'application/json'},
        body: JSON.stringify(contact)
      });
      return result;
    }

    render() {
        const { name, email, phone, message, loading, alertMessage } = this.state;
        return (
            <section id="contact" className="contact-area ptb-100 pt-0">
                <div className="container">
                    <div className="saas-section-title">
                        <h2>Contáctanos</h2>
                        <div className="bar"></div>
                    </div>

                    <div className="contact-form">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="contact-info-box">
                                    <p>Nuestro equipo está para ayudarte. Si tienes dudas con tu mantención o reparación, sólo contáctanos.</p>

                                    <span>Escríbenos:
                                        <a href="mailto:contacto@carrascohermanos.cl">contacto@carrascohermanos.cl</a>
                                    </span>
                                    <span>Llámanos:
                                        <a href="tel:+56226998340">(56-2) 2699 8340</a>
                                    </span>
                                    <span className="row">
                                        <a href="https://www.waze.com/ul?ll=-33.54503470%2C-70.65973880&navigate=yes">
                                            <img src={require("../../images/waze.png")} alt="Waze" />
                                        </a>
                                        <a href="https://goo.gl/maps/ddBV3nm4LRDk3mNc7">
                                            <img src={require("../../images/gmaps.png")} alt="Google Maps" />
                                        </a>
                                    </span>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <form id="contactForm" onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input
                                                  id="name"
                                                  type="text"
                                                  className="form-control"
                                                  required={true}
                                                  data-error="Ingresa nombre"
                                                  value={name}
                                                  onChange={e => this.handleChange(e)}/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input
                                                  id="email"
                                                  type="email"
                                                  className="form-control"
                                                  required={true}
                                                  data-error="Ingresa tu email"
                                                  value={email}
                                                  onChange={e => this.handleChange(e)}/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Teléfono</label>
                                                <input
                                                  id="phone"
                                                  type="number"
                                                  className="form-control"
                                                  required={true}
                                                  data-error="Ingresa tu teléfono"
                                                  value={phone}
                                                  onChange={e => this.handleChange(e)}/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Mensaje</label>
                                                <textarea
                                                  id="message"
                                                  className="form-control"
                                                  cols="30"
                                                  rows="4"
                                                  required={true}
                                                  data-error="Ingresa tu mensaje"
                                                  value={message}
                                                  onChange={e => this.handleChange(e)}/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                            {loading ? "Enviando...": "Enviar"}
                                            </button>
                                            <div id="msgSubmit" className="h3 text-center hidden"></div>
                                            <div id="msgSubmit" className="h3 text-center">{alertMessage}</div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;
