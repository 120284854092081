import React, { Component } from 'react';
import Typist from 'react-typist';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import BackgroundSlider from 'react-background-slider'

import image1 from '../../images/machinary/banner/banner1.jpg'
import image2 from '../../images/machinary/banner/banner2.jpg'
import image3 from '../../images/machinary/banner/banner3.jpg'

class Banner extends Component {

    render() {
        return (
            <React.Fragment>
                <div id="home" className="main-banner jarallax" data-jarallax='{"speed": 0.2}' style={{height: '500px'}}>
                <BackgroundSlider images={[image1, image2, image3]} duration={8} transition={2}/>
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="main-banner-content">
                                    <h1>
                                      <br/  >
                                        <Typist startDelay={500} avgTypingDelay={80} cursor={{show: true, blink: true, element: '|', hideWhenDone: true, hideWhenDoneDelay: 500}}>
                                            <span>Reparación de todo tipo de vehículos y motores Diesel</span>
                                        </Typist>
                                        <AnchorLink offset={() => 0} className="btn btn-primary" href="#about">Empecemos!</AnchorLink>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Banner;
