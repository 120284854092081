import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Footer extends Component {
    render() {
        return (
            <footer className="footer-area bg-fffcf4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="single-footer-widget">
                                <div className="logo">
                                    <Link to="/">
                                        <img src={require('../../images/black-logo.png')} alt=""/>
                                    </Link>
                                </div>

                                <ul className="contact-info">
                                    <li>Carlos Condell 489, La Cisterna, Santiago</li>
                                    <li>
                                        <a href="mailto:contacto@carrascohermanos.cl">contacto@carrascohermanos.cl</a>
                                    </li>
                                    <li>
                                        <Link to="/">www.carrascohermanos.cl</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copyright-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-md-7">
                                <p><i className="far fa-copyright"></i> 2019 <Link to="/">Carrasco Hermanos</Link>. Todos los derechos reservados</p>
                            </div>

                            <div className="col-lg-5 col-md-5">
                                <p style={{textAlign: 'center'}}>
                                    Sitio desarrollado por <a href="https://www.neokode.cl" target="_blank" rel="noopener noreferrer">Neokode</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="map2">
                    <img src={require('../../images/map2.png')} alt="map" />
                </div>
            </footer>
        );
    }
}

export default Footer;
