import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

class About extends Component {
    render() {
        return (
            <section id="about" className="about-area ptb-100">
                <div className="container">
                    <div className="saas-section-title">
                        <h2>Nosotros</h2>
                        <div className="bar"></div>
                        <p>Carrasco Hermanos, empresa familiar que lleva 30 años como especialistas en la industria de la mecánica e ingenieria automotriz multimarca. Somos profesionales expertos en solucionar los problemas de tu vehículo, <b>te aseguramos que no segurás buscando</b>.</p>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="about-content">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4">
                                        <div className="about-image">
                                            <img src={require('../../images/about1.jpg')} alt="about" />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-car"></i>
                                                </div>

                                                <h3>Mantenimiento preventivo</h3>
                                            </div>

                                            <p>Nos preocupamos del mantenimiento periódico de tu vehículo o flota de acuerdo a la pauta del fabricante</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-car"></i>
                                                </div>

                                                <h3>Scanner y diagnóstico de inyección</h3>
                                            </div>

                                            <p>Servicio que incluye diversa lecturas de falla y un análisis en detalle de la situación que presenta tu vehículo</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4">
                                        <div className="about-image">
                                            <img src={require('../../images/about2.jpg')} alt="about" />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-car"></i>
                                                </div>

                                                <h3>Sistema de inyección diesel</h3>
                                            </div>

                                            <p>Contamos con taller, laboratorio y tecnología de última generación especializado en mantenimiento y reparación de sistemas de inyección diesel</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-car"></i>
                                                </div>

                                                <h3>Reparaciones eléctricas automotriz</h3>
                                            </div>

                                            <p>Solucionamos todo tipo de problemas: motor de partida, alternador, luces, aire acondicionado, calefacción, entre otros</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4">
                                        <div className="about-image">
                                            <img src={require('../../images/about3.jpg')} alt="about" />
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-car"></i>
                                                </div>

                                                <h3>Alineación y balanceo electrónico</h3>
                                            </div>

                                            <p>Ajustamos tu vehículo con alineación virtual y maquina inhálambrica. Tecnología a tu servicio.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-car"></i>
                                                </div>

                                                <h3>Reparaciones múltiples</h3>
                                            </div>

                                            <p>Somos expertos en atender amplia variedad de reparaciones mecánicas. Contamos con basta experiencia en el rubro.</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="section-title">
                                    <AnchorLink offset={() => 0} className="btn btn-primary" href="#services">Ver más...</AnchorLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="circle"><img src={require('../../images/circle.png')} alt="cricle" /></div>
            </section>
        );
    }
}

export default About;
