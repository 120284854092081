import React, { Component } from 'react';
import NavbarThree from '../components/Layouts/NavbarThree';
import Footer from '../components/Layouts/Footer';
import About from '../components/Home/About';
import Banner from '../components/Home/Banner';
import Services from '../components/Home/Services';
import Contact from '../components/Home/Contact';
import Partner from '../components/Home/Partner';

class Home extends Component {
    render() {
        return (
            <React.Fragment>
              <NavbarThree />
              <Banner />
              <About />
              <Services />
              <Partner />
              <Contact />
              <Footer />
            </React.Fragment>
        );
    }
}

export default Home;
